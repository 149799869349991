<template>
    <div>
      <div class="flex md12">

        <va-modal
          v-model="editingResource"
          hideDefaultActions
          class="flex xs12"
          title="Editing Resource Details"
          size="large"
          ok-text="Ok">
          <section>  
            <EditResourceForm @updated="closeEditingResource" :data="currentEditingResource"/>
          </section>
        </va-modal>


        <va-card title="NIHSS+ Resources">
          <div class="row align--center">
            <div class="flex xs12 md6">
              <va-input
                :value="term"
                :placeholder="'Search'"
                @input="search"
                removable
              >
                <va-icon
                  name="fa fa-search"
                  slot="prepend"
                />
              </va-input>
            </div>
            <div class="flex xs12 md3 offset--md3">
              <va-select
                v-model="perPage"
                :label="$t('tables.perPage')"
                :options="perPageOptions"
                no-clear
              />
            </div>
          </div>
          <va-data-table
            :fields="fields"
            :data="filteredData"
            :per-page="parseInt(perPage)"
            :loading="loading"
            clickable
            hoverable
          >
          <template slot="view_url" slot-scope="props">
            <div class="w-50 h-10 text-sm">
                <va-button flat small class="w-50 h-10 text-sm" :disabled="!props.rowData.URL" @click="viewMore(props.rowData.URL)" title="Go To URL"   icon="fa fa-link">
                  View
                </va-button>
            </div>
          </template>
          <template slot="actions" slot-scope="props" >
            <div class="flex row">
                <va-button :disabled="!props.rowData.imageUrl" @click="viewMore(props.rowData.imageUrl)" title="Go To URL"   icon="fa fa-file-image-o"/>
                <va-button @click="editData(props.rowData)" title="Edit Resource"   icon="fa fa-edit"/>
                <va-button @click="deleteData(props.rowData.id, props.rowData.imageRef)" title="Delete Resource"  icon="fa fa-trash"/>
            </div>
          </template>
         </va-data-table>
        </va-card>
      </div>
    </div>
  </template>
  <script>
  import {debounce} from 'lodash';
  import moment from 'moment';
  import firebase from 'firebase';
  import axios from 'axios';
  import LogCreator from "../../class/LogCreator"
  import EditResourceForm from './edit.vue'
  export default {
    data() {
      return {
        perPageOptions: ['10', '50', '100', '250'],
        perPage: '10',
        resources: [],
        currentResource: [],
        currentChangingCC: [],
        hasChanges: false,
        currentData: {},
        CurrentEditingTable: false,
        CurrentTicketPopup: false,
        editingResource: false,
        term: '',
        loading: false,
        currentEditingResource: []
      };
    },
    components: {
      EditResourceForm
    },
    async created() {
      await this.updateTable()
      this.loading = false;
    },
    computed: {
      fields() {
        return [{
          name: 'title',
          title: 'Title',
          sortField: 'title',
          width: '13%',
        },{
          name: 'order',
          title: 'Order',
          sortField: 'order',
          width: '13%',
        },
        {
          name: 'active',
          title: 'Active',
          sortField: 'active',
          width: '16%',
        },
        {
          name: '__slot:view_url',
          title: 'Resource Url',
          width: '16%',
        },
        {
          name: '__slot:actions',
          title: 'Actions',
          width: '20%',
        },
        ];
      },
      filteredData() {
        //order by createdAt
        this.resources.sort((a, b) => {
            return new Date(b.order) - new Date(a.order);
        });
        if (!this.term || this.term.length < 1) {
          return this.resources;
        }
        return this.resources.filter((item) => {
          let returnData = false;
          if (item.title) {
            if (item.title.toLowerCase().indexOf(this.term.toLowerCase()) !== -1) {
              returnData = true;
            }
          }
          return returnData;
        });
      },
    },
   
    methods: {
      async updateTable(){
        this.resources = []
        await firebase.firestore().collection('resources').get().then((snapshot) => {
            const resources = snapshot.docs.map(doc => {
                let data = doc.data();
                //foreach item in data.file.fileJson append a random index key
                this.resources.push({
                    id: doc.id,
                    title: data.title,
                    active: data.active,
                    isResource: data.isResource,
                    imageUrl: data?.imageUrl ? data?.imageUrl : null,
                    imageRef: data?.imageRef ? data?.imageRef : null,
                    URL: data?.URL ? data?.URL : null,
                    order: data.order,
                })
            })
          })
      },
      async viewMore(data){
        //open new tab with image url
        window.open(data, '_blank');
      },
      async editData(data){
        this.currentEditingResource = data
        this.editingResource = true;
      },
      closeEditingResource(){
        this.editingResource = false;
        this.updateTable()
      },
      async deleteData(id, imageRef){
          this.$swal.fire({
              title: 'Are you sure?',
              html: "You won't be able to revert this! This will completely remove Resource with Id: <b>" + id + "</b> from the firestore!",
              icon: 'warning',
              showDenyButton: false,
              showCancelButton: true,
              confirmButtonText: 'Yes',
          }).then(async (result) => {
            if(result.isConfirmed){
              if(imageRef){
                //check if imageRef starts with resources/images
                if(imageRef.startsWith('/resources/images')){
                  //delete image from storage
                  try {
                    await firebase.storage().ref(imageRef).delete()
                  } catch (error) {
                    console.log(error)
                  }
                }
              }
              await firebase.firestore().collection('resources').doc(id).delete().then(() => {
                this.$swal.fire({
                  title: 'Deleted!',
                  text: 'Resource has been deleted.',
                  icon: 'success',
                  confirmButtonText: 'Ok'
                }).then(() => {
                  this.updateTable()
                })
              }).catch((error) => {
                throw new Error(error.message)
              })
            }
          })
      },
      search: debounce(function(term) {
        /* eslint-disable */
        this.term = term;
      }, 100),
    }
  };
  </script>
  